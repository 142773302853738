<template>
  <section class="securities">
    <section class="hero-small">
      <div>
        <h1>Les garanties et les engagements de votre constructeur</h1>
      </div>
    </section>
    <article class="grid-container article">
      <div class="article-title fit-content">
        <h3>
          Maisons Alysia, en tant que constructeur de maisons individuelles affirmant sa
          responsabilité, s’engage pour assurer et garantir chaque chantier, de sa mise en œuvre
          jusqu’à la livraison et bien au delà.
        </h3>
      </div>
      <div class="article-cards">
        <card-type-img title="Toutes les garanties Maisons Alysia">
          <template v-slot:content>
            <p>
              <strong>Un prix convenu :</strong> une maison livrée au prix convenu dans le contrat de construction
              et garanti par un assureur.
            </p>
            <p>
              <strong>Le déroulement des travaux :</strong> date d’ouverture du chantier, délai
              d’exécution des travaux, pénalités en cas de retard de livraison, garantie de
              livraison, tout est prévu et contractuel.
            </p>
            <p>
              <strong>La conformité des normes constructives</strong> relatives au Code de
              l’Urbanisme et de la Construction.
            </p>
            <p><strong>Le dépôt et l’obtention du permis de construire.</strong></p>
            <p><strong>Un contrat de construction</strong> agréé et réglementé.</p>
            <p><strong>Un plan détaillé et une notice descriptive.</strong></p>
            <p>
              <strong>Une solide assurance</strong> reconnue pour sa solvabilité permettant d’offrir
              toutes les garanties.
            </p>
            <p><strong>Un échéancier de paiement</strong> réglementé.</p>
            <p><strong>La sélection d’artisans reconnus</strong>, expérimentés et assurés.</p>
            <p>
              <strong>Un interlocuteur unique</strong> à toutes les étapes, du permis de construire
              à la livraison.
            </p>
          </template>
          <template v-slot:img>
            <img src="@/assets/img/pages/securities-2.jpg" alt="Garanties Alysia" />
          </template>
        </card-type-img>
        <card-type-img title="Le Contrat de Construction de Maison Individuelle (CCMI)">
          <template v-slot:content>
            <p class="article-text-big">
              Soumis à des dispositions d’ordre public auxquelles il est impossible de déroger,
              <router-link to="/constructeur-maisons/avantages-CCMI">le CCMI</router-link> est
              entièrement dédié à la protection de l’acheteur. Avec ce contrat, vous êtes
              certain de voir votre maison achevée, à des prix et délais convenus (loi du 19
              décembre 1990).
            </p>
            <ul>
              <li>La garantie de livraison à prix et délais convenus.</li>
              <li>L’Assurance Dommages-Ouvrage.</li>
              <li>La garantie de paiement des sous-traitants.</li>
              <li>La garantie de remboursement.</li>
              <li>La garantie de parfait achèvement.</li>
              <li>La garantie de bon fonctionnement.</li>
              <li>La garantie décennale.</li>
            </ul>
            <p>
              Au cœur du dispositif et pour l’entière protection de l’acheteur, l’Assurance
              Dommages-Ouvrage garantit le remboursement et la réparation des dommages qui se
              produisent après la réception des travaux et qui engagent les entreprises qui ont
              participé à la construction du logement et ce, sans attendre une décision de justice.
              Elle reste effective jusqu’à la fin de la garantie décennale. Une disposition du
              constructeur qui permet d’envisager la réalisation de son projet et la jouissance de
              son bien en toute sérénité.
            </p>
          </template>
          <template v-slot:img>
            <img
              src="@/assets/img/pages/securities-3.jpg"
              alt="Contrat de Construction de Maison Individuelle (CCMI)"
            />
          </template>
        </card-type-img>
      </div>
    </article>
  </section>
</template>

<script>
import CardTypeImg from '@/components/general/CardTypeImg.vue';

export default {
  name: 'securities',
  components: {
    CardTypeImg,
  },
};
</script>

<style lang="sass">
.securities
  background-color: $medium-bg
  padding-bottom: 6rem
  .hero-small
    @include hero-small
    backdrop-filter: opacity(20%)
    background-image: linear-gradient(180deg, rgba(10, 10, 10, 0.6) 0%, rgba(32, 32, 32, 0.2) 100%), url(~@/assets/img/pages/securities-1.jpg)
    > div
      width: 90%
      max-width: 900px
      margin: 0 auto
  .article
    &-title
      margin: 6rem auto
      text-align: center
    &-cards
      > div:first-child
        margin-bottom: 3rem
        p
          @include subtitle-mobile
          &:not(:last-child)
            margin-bottom: 0.5rem
      ul
        padding: 2.25rem 0 2.25rem 0 !important
        li
          list-style-type: none
          &:before
            content: ' '
            display: inline-block
            margin-right: 0.5rem
            transform: translateY(0.3rem)
            width: 20px
            height: 20px
            background-image: url("../../assets/img/arrow-blue.svg")
            background-repeat: no-repeat
          &:not(:last-child)
            margin-bottom: 1rem
    &-text-big
      @include subtitle-mobile
  .fit-content
    width: 90%;
    max-width: 950px;
  @media (max-width: 768px)
    .article
      &-title
        margin: 4.5rem auto
      &-cards
        ul
          padding: 1.5rem 0 1.5rem 2rem !important
          li:not(:last-child)
            margin-bottom: 0.25rem
</style>
